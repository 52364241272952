@font-face {
  font-family: 'NunitoSans';
  src: url('../public/assets/font/NunitoSans_7pt-Medium.ttf') format('truetype');
  /* Otros atributos opcionales */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'NunitoSans','Nunito Sans', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    background-color: /*#FFA552 !important*/primaryColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('https://rare-gallery.com/uploads/posts/556883-cafeteria-coffee.jpg');
  background-attachment: fixed;
  background-size: cover; */
}

